.App {
   text-align: center;
}

.App-logo {
   height: 40vmin;
   pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
   .App-logo {
      animation: App-logo-spin infinite 20s linear;
   }
}

.App-header {
   background-color: #282c34;
   min-height: 100vh;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   font-size: calc(10px + 2vmin);
   color: white;
}

.App-link {
   color: #61dafb;
}

@keyframes App-logo-spin {
   from {
      transform: rotate(0deg);
   }
   to {
      transform: rotate(360deg);
   }
}

/* NZT SYNC */

svg {
   vertical-align: initial !important;
}

.marketplace_sync_column {
   padding-left: 2px !important;
   padding-right: 2px !important;
   width: 24px !important;
   text-align: center !important;
   font-size: 14px;
}

.card-dashboard-box {width:100%;}
.card-dashboard-box h1, .card-dashboard-box h2, .card-dashboard-box h4 {
  margin:0 !important;
}
.chart-in-card {
  margin: 0 -24px -24px -24px !important;
}

button.link {
  border: 0px;
  box-shadow: none;
  padding: 0px;
  margin: 0px;
  background-color: transparent;
  height: unset;
  font-size: unset;
}

button.link span {
  text-decoration: underline;
}

.movement-charge-column {font-weight:bold; color: green}
.movement-discharge-column {font-weight:bold; color: red}